// client/src/components/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';  
import LandingPage from './views/LandingPage/LandingPage';
import EmailAndMFAEntry from './views/ShareForm/EmailAndMFAEntry';
import SharedDetails from './views/ShareForm/SharedDetails';

function App() {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/checksum/:permalink" element={<EmailAndMFAEntry />} />
            <Route path="/securedpass/:permalink" element={<SharedDetails />} />
        </Routes>
    );
}

export default App;
