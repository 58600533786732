// client/src/components/ShareForm/SharedDetails.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/index.css';  // Importing the CSS file

function SharedDetails() {
    const { permalink } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState(null);
    const [error, setError] = useState(null);
    const [countdown, setCountdown] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0); // Progress for loading bar

    useEffect(() => {
        const fetchDetails = async () => {
            const expirationTime = localStorage.getItem('mfaExpiration');
            const now = Date.now();

            if (!expirationTime || now > parseInt(expirationTime)) {
                alert('Your session has expired or is invalid. Please re-enter the Authentication.');
                navigate(`/checksum/${permalink}`);
                return;
            }

            const remainingTime = parseInt(expirationTime) - now;
            setCountdown(Math.floor(remainingTime / 1000)); // Set countdown in seconds initially

            // Simulate loading progress (for progress bar)
            const loadingInterval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(loadingInterval);
                        return prevProgress;
                    }
                    return prevProgress + 20; // Increment progress by 20%
                });
            }, 500);

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/share/details/${permalink}`, {
                    headers: {
                        'X-Permalink': permalink
                    },
                    withCredentials: true
                });
                setDetails(response.data);
            } catch (err) {
                console.error('Error fetching details:', err.response?.data?.error || err.message);
                setError(err.response?.data?.error || 'Failed to fetch details');
            }
        };

        fetchDetails();

        // Update the countdown every second
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(interval);
                    alert('Your session has expired. Please re-enter the Authentication.');
                    navigate(`/checksum/${permalink}`);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [permalink, navigate]);

    // Function to format time as MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    if (error) {
        return (
            <div>
                <p>{error}</p>
            </div>
        );
    }

    // Spinner or progress bar when details are loading
    if (!details) {
        return (
            <div className="loading-container">
                {/* Option 1: Spinner */}
                {/* <div className="spinner"></div> */}

                {/* Option 2: Progress Bar */}
                <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${loadingProgress}%` }}></div>
                </div>
            </div>
        );
    }

    return (
        <div className="shared-details-container">
            <img src="%PUBLIC_URL%/../../../favi.png" alt="Password Share Logo" className="logo-image" />
            <h1>Shared Details</h1>
            <div className="cards-container">
                {details.credentials.map((credential, index) => (
                    <div key={index} className="credential-card">
                        <h3>Credential Set {index + 1}</h3>
                        <p><strong>Website Name:</strong> {credential.websiteName}</p>
                        <p><strong>Website URL:</strong> {credential.websiteURL}</p>
                        <p><strong>Username/Email:</strong> {credential.usernameOrEmail}</p>
                        <p><strong>Password:</strong> {credential.password}</p>
                    </div>
                ))}
            </div>
            <p className="session-countdown"><strong>Session expires in:</strong> {formatTime(countdown)}</p>
        </div>
    );
}

export default SharedDetails;
