import React, { useState } from 'react';
import axios from 'axios';
import '../../../styles/index.css';  // Importing the CSS file

function ShareForm() {
    const [credentialSets, setCredentialSets] = useState([
        {
            websiteName: '',
            websiteURL: '',
            usernameOrEmail: '',
            password: '',
        }
    ]);
    const [shareWithEmail, setShareWithEmail] = useState('');
    const [permalink, setPermalink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [copySuccess, setCopySuccess] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState(false);


    const handleChange = (index, e) => {
        const updatedCredentialSets = [...credentialSets];
        updatedCredentialSets[index] = {
            ...updatedCredentialSets[index],
            [e.target.name]: e.target.value
        };
        setCredentialSets(updatedCredentialSets);
    };

    const handleAddCredentialSet = () => {
        setCredentialSets([...credentialSets, {
            websiteName: '',
            websiteURL: '',
            usernameOrEmail: '',
            password: '',
        }]);
    };

    const handleRemoveCredentialSet = (index) => {
        const updatedCredentialSets = credentialSets.filter((_, i) => i !== index);
        setCredentialSets(updatedCredentialSets);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        // Validation to ensure that no password is missing
        for (let i = 0; i < credentialSets.length; i++) {
            const { password } = credentialSets[i];
            if (!password) {
                setError(`Password for Credential Set ${i + 1} is required`);
                setIsLoading(false);
                return;
            }
        }

        try {
            const formData = {
                credentialSets,
                shareWithEmail
            };

            const res = await axios.post(`${process.env.REACT_APP_API_URL.trim()}/share/create`, formData);
            const generatedPermalink = res.data.permalink;
            setPermalink(generatedPermalink);

            setIsSendingEmail(true);
            await axios.post(`${process.env.REACT_APP_API_URL.trim()}/share/send-link`, {
                email: shareWithEmail,
                permalink: generatedPermalink
            });
            setIsSendingEmail(false);

        } catch (err) {
            console.error(err.response ? err.response.data.error : err.message);
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/checksum/${permalink}`)
            .then(() => {
                setCopySuccess('Copied!');
                setTimeout(() => setCopySuccess(''), 2000);
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <div className="form-container">
            {!permalink ? (
                <form className="share-form" onSubmit={handleSubmit}>
                    {credentialSets.map((set, index) => (
                        <div key={index} className="credential-set">
                            <input
                                type="text"
                                name="websiteName"
                                placeholder="Website Name..."
                                value={set.websiteName}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                            <input
                                type="text"
                                name="websiteURL"
                                placeholder="Website URL..."
                                value={set.websiteURL}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                            <input
                                type="text"
                                name="usernameOrEmail"
                                placeholder="Username/Email..."
                                value={set.usernameOrEmail}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password..."
                                value={set.password}
                                onChange={(e) => handleChange(index, e)}
                                required
                            />
                            {credentialSets.length > 1 && (
                                <button
                                    type="button"
                                    className="remove-btn"
                                    onClick={() => handleRemoveCredentialSet(index)}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button type="button" className="add-btn" onClick={handleAddCredentialSet}>
                        Add Another Credential Set
                    </button>
                    <input
                        type="email"
                        name="shareWithEmail"
                        placeholder="Share with email... e.g. info@example.com"
                        value={shareWithEmail}
                        onChange={(e) => setShareWithEmail(e.target.value)}
                        required
                    />
                    <button type="submit" className="share-btn" disabled={isLoading}>
                        {isLoading ? 'Sharing...' : 'Securely Share'}
                    </button>
                    {isLoading && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                    )}
                    {error && <p className="error-message">{error}</p>}
                </form>
            ) : (
                <div className="share-link">
                    {isSendingEmail ? (
                        <div className="loading-container">
                            <p>Sending email...</p>
                            <div className="loading-spinner"></div>
                        </div>
                    ) : (
                        <>
                            <p>The link has been sent to the recipient's email.</p>
                            <p>You can also share this link manually if needed:</p>
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}/checksum/${permalink}`} className="custom-link">
                                {`${process.env.REACT_APP_FRONTEND_URL}/checksum/${permalink}`}
                            </a>
                            <button className="copy-btn" onClick={handleCopyToClipboard}>Copy to Clipboard</button>
                            {copySuccess && <p>{copySuccess}</p>}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default ShareForm;