// src/components/LandingPage/LandingPage.js
import React from 'react';
import ShareForm from '../ShareForm/ShareForm';  // Path to ShareForm
import '../../../styles/index.css';  // Importing the CSS file

function LandingPage() {
    return (
        <div className="landing-page">
            <div className="form-container-main">
                <img src="%PUBLIC_URL%/../../../favi.png" alt="Password Share Logo" className="logo-image" />
                <h1>Encrypted Password Share</h1>
                <h2>Military-Grade 256 Bit Encryption.</h2>
                <ShareForm /> {/* Embed the form */}
            </div>
        </div>
    );
}

export default LandingPage;
