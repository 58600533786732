//client/public/src/ShareForm/EmailAndAndMFAEntry.js

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/index.css';  // Importing the CSS file


function EmailAndMFAEntry() {
    const { permalink } = useParams();  // Get permalink from URL
    const [email, setEmail] = useState('');  // Email input state
    const [code, setCode] = useState('');  // Authentication input state
    const [step, setStep] = useState(1);  // Control which step is shown (1 = email, 2 = MFA)
    const [error, setError] = useState('');  // Error message state
    const navigate = useNavigate();  // For navigation after successful verification

    // Handle email verification
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setError('');  // Clear previous errors
        try {
            // Send request to backend to verify email and send Authentication
            //await axios.post(`http://localhost:5001/api/share/verify-email`, { email, permalink });
            await axios.post(`${process.env.REACT_APP_API_URL}/share/verify-email`, { email, permalink });
            setStep(2);  // Move to the Authentication code step
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to verify email.');
        }
    };

    // Handle Authentication verification
// Handle Authentication verification
const handleMFASubmit = async (e) => {
    e.preventDefault();
    setError('');  // Clear previous errors
    try {
        // Verify the Authentication
        //await axios.post(`http://localhost:5001/api/share/verify-mfa`, { code, permalink });
        await axios.post(`${process.env.REACT_APP_API_URL}/share/verify-mfa`, { code, permalink });


        // Store the session expiration time in localStorage
        localStorage.setItem('mfaExpiration', Date.now() + 30 * 60 * 1000); // 30 minutes


        // Navigate to the shared details page if verification is successful
        navigate(`/securedpass/${permalink}`);
    } catch (err) {
        setError(err.response?.data?.error || 'Failed to verify Authentication.');
    }
};


    return (
        <div className="email-mfa-container">
            <img src="%PUBLIC_URL%/../../../favi.png" alt="Password Share Logo" className="logo-image" />
            <h1>{step === 1 ? 'Enter Your Email' : 'Enter Authentication Code'}</h1>

            {step === 1 && (
                <form className="email-mfa-form" onSubmit={handleEmailSubmit}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Send Authentication Code</button>
                </form>
            )}

            {step === 2 && (
                <form className="email-mfa-form" onSubmit={handleMFASubmit}>
                <input
                    type="text"
                    placeholder="Enter Authentication Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                />
                <button type="submit">Verify Code</button>
                </form>
            )}

            {error && <p className="error-message">{error}</p>}
        </div>

    );
}

export default EmailAndMFAEntry;
